import React, { useState, useEffect } from "react";
import "./Header.scss";
import Btn from "../../elements/Btn/Btn";
import logo from "../../../img/logo.jpg";
import MenuMobile from "../../elements/MenuMobile/MenuMobile";
import Category from "../../elements/Category/Category";
import { Link } from "react-router-dom";

const Header = ({back,itemsC,setItemsC,itemsSubC,setItemsSubC}) => {

    const [hover, setHover] = useState(false);
    
    const handleMouseEnter = () =>{
        setHover(true);
    }
    const handleMouseLeave = () =>{
        setHover(false);
    }

    return (
        <div className="nav">
            <div className="menu-m">
                <MenuMobile 
                    itemsC={itemsC}
                    setItemsC={setItemsC}
                    itemsSubC={itemsSubC}
                    setItemsSubC={setItemsSubC}
                />
            </div>
            <div className="logo">
                <Link to={"/"}>
                    <img src={logo} alt="Logo" />
                </Link>
            </div>
            <div className="container-section">
                <ul className="sections">
                    {
                        back &&
                        <li>
                            <Btn link type="link" text="Inicio" route="/" />
                        </li>
                    } 
                    
                    <li 
                        className="menu-products"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Btn link type="link" text="Productos" route="/productos"/>
                        {
                            hover && 
                            <Category 
                                itemsC={itemsC}
                                setItemsC={setItemsC}
                                itemsSubC={itemsSubC}
                                setItemsSubC={setItemsSubC}
                            />
                        }                        
                    </li>                    
                    <li>
                        <Btn link type="link" text="Nuestra Empresa" route="/nosotros" />
                    </li>
                    <li>
                        <Btn link type="link" text="Contacto" route="/contacto" />
                    </li>               
                </ul>
            </div>
        </div>
    );
}
 
export default Header;