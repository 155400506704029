import React, {useState, useRef, useEffect  } from "react";
import "./Home.scss";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import BannerHomeInit from "../../elements/BannerHomeInit/BannerHomeInit";
import Slider from "../../elements/Slider/Slider";
import TitleSlider from "../../elements/TitleSlider/TitleSlider";
import BannerHomeEnd from "../../elements/BannerHomeEnd/BannerHomeEnd";
import useGetCategory from '../../hooks/useGetCategory';
import useGetProducts from "../../hooks/useGetProducts";
import useGetSubCategories from "../../hooks/useGetSubCategories";
import Loading from "../../shared/Loading/Loading";

// Registrar el plugin de ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

const Home = () => {

    const boxRef = useRef(null);
    /*GSAP animaciones*/
    useEffect(() => {
        gsap.fromTo(boxRef.current, 
            { opacity: 0.2, y: 50 }, 
            { 
                opacity: 1, 
                y: 0, 
                scrollTrigger: {
                    trigger: boxRef.current,
                    start: 'top 80%', // Inicia la animación cuando el top del elemento esté en el 80% del viewport
                    end: 'top 30%', // Termina la animación cuando el top del elemento esté en el 30% del viewport
                    scrub: true, // La animación seguirá el scroll
                }
            }
        );
        }, []);

    const [itemsC, setItemsC] = useState({});
    const [itemsP, setItemsP] = useState({});
    const [itemsSubC, setItemsSubC] = useState({});
    
    const urlCategories = 'https://www.obleasdulcesbsas.com.ar/api/getCategory.php';
    const urlProducts = 'https://www.obleasdulcesbsas.com.ar/api/getProduct.php';
    const urlSubCategories = 'https://www.obleasdulcesbsas.com.ar/api/getSubCategory.php';
    const postData = { "state": "A" };
  
    const { data: categories, loading: loadingCategories, error: errorCategories } = useGetCategory(urlCategories, postData);
    const { data: products, loading: loadingProducts, error: errorProducts } = useGetProducts(urlProducts,postData);
    const { data: subCategories, loading: loadingsubCategories, error: errorsubCategories } = useGetSubCategories(urlSubCategories,postData);
  
    useEffect(() => {
      if(categories){setItemsC(categories)};
      if(products){setItemsP(products)};
      if(subCategories){setItemsSubC(subCategories)};
    }, [categories,products]);
  
    if (loadingCategories || loadingProducts || loadingsubCategories) return <Loading />;
    if (errorCategories || errorProducts || errorsubCategories) return <p>Error: {errorCategories?.message || errorProducts?.message}</p>;
    
    return (
        <>
            <BannerHomeInit/>
            <div className="container-p" ref={boxRef}>
                <p>
                    Obleas Dulces Bs. As. S.R.L. es una empresa que desde siempre viene ofreciendo al mercado productos artesanales de alta calidad combinando las mejores materias primas y cuidando los procesos de elaboración,
                    envasado y distribución para que lleguen al cliente en óptimas condiciones.
                </p>
                <p>
                    Nuestros productos son elaborados de forma totalmente artesanal y no industrial, por lo tanto no contienen ningún tipo de conservantes
                    ni mejoradores químicos, logrando de esta manera una oblea natural, rica y saludable.
                </p>
            </div>
            <TitleSlider info="Nuestros productos"/>
            <Slider 
                style={itemsC.type}
                slidesPerView="5" 
                spaceBetween="20"
                typeOfItem={itemsC.type}
                items={itemsC}
                setItems={setItemsC}
            />
            <TitleSlider info="Nuestros Packs"/>
            <Slider 
                style={itemsP.type}
                slidesPerView="4" 
                spaceBetween="0"
                typeOfItem={itemsP.type}
                items={itemsP}
                setItems={setItemsP}
            />
            <TitleSlider info="Novedades"/>
            <Slider 
                style={itemsSubC.type}
                slidesPerView="4" 
                spaceBetween="20"
                typeOfItem={itemsSubC.type}
                items={itemsSubC}
                setItems={setItemsSubC}
            />
            <BannerHomeEnd />
        </>
    );
}
 
export default Home;