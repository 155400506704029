import React ,{useState,useEffect}from "react";
import "./Category.scss";
import {useNavigate } from "react-router-dom";

const Category = ({itemsC,setItemsC,itemsSubC,setItemsSubC}) => {

    const navigate = useNavigate ();

    const handleClick = (route, item, type) => {
        let postData;
        
        if (type === "category") {
            postData = { "id_category": item.id };
        } else if(type === "subcategory") {
            postData = { "id_subcategory": item.id };
        }else{
            postData = { "state": "A" };
        }
        navigate(route, { state: { item, postData } });
    }

    return (
        <div className="products-expand">
            <ul>
                {itemsC && Array.isArray(itemsC.data) ? (
                    itemsC.data.map((item, index) => (
                        <li key={index}>
                            <a
                                className="category"
                                onClick={() => handleClick("/productos", item, "category")}
                            >
                                {item.name}
                            </a>
                            {itemsSubC && Array.isArray(itemsSubC.data) && itemsSubC.data.map((itemSub, index) => (
                                itemSub.id_category === item.id ? (
                                    <p
                                        key={index}
                                        className="sub-category"
                                        onClick={() => handleClick("/productos", itemSub, "subcategory")}
                                    >
                                        {itemSub.name}
                                    </p>
                                ) : null
                            ))}
                        </li>
                    ))
                ) : (
                    <p>No categories available</p>
                )}
            </ul>
        </div>
    );
}
 
export default Category;