import React from "react";
import "./MenuMobile.scss";
import { slide as Menu } from 'react-burger-menu';
import {useNavigate } from "react-router-dom";
import Btn from "../Btn/Btn";

const MenuMobile = ({itemsC,setItemsC,itemsSubC,setItemsSubC}) => {

    const navigate = useNavigate ();

    const handleClick = (route, item, type) => {
        let postData;
        
        if (type === "category") {
            postData = { "id_category": item.id };
        } else if(type === "subcategory") {
            postData = { "id_subcategory": item.id };
        }else{
            postData = { "state": "A" };
        }
        navigate(route, { state: { item, postData } });
    }

    return ( 
        <Menu>
            <span>Nuestros Productos:</span>
            <ul className="menu-m-categ">
                {itemsC && Array.isArray(itemsC.data) ? (
                    itemsC.data.map((item, index) => (
                        <li key={index}>
                            <a
                                className="category"
                                onClick={() => handleClick("/productos", item, "category")}
                            >
                                {item.name}
                            </a>
                            {itemsSubC && Array.isArray(itemsSubC.data) && itemsSubC.data.map((itemSub, index) => (
                                itemSub.id_category === item.id ? (
                                    <p
                                        key={index}
                                        className="sub-category"
                                        onClick={() => handleClick("/productos", itemSub, "subcategory")}
                                    >
                                        {itemSub.name}
                                    </p>
                                ) : null
                            ))}
                        </li>
                    ))
                ) : (
                    <p>No categories available</p>
                )}
            </ul>
            <ul className="menu-m-links">
                <li>
                    <Btn link type="link" text="Productos" route="/productos" />
                </li>
                <li>
                    <Btn link type="link" text="Nuestra Empresa" route="/nosotros" />
                </li>
                <li>
                    <Btn link type="link" text="Contacto" route="/contacto" />
                </li>
            </ul>
        </Menu>
     );
}
 
export default MenuMobile;