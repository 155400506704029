import React from "react";
import "./TitleSlider.scss";

const TitleSlider = (props) => {
    return ( 
        <>
        {
            props.info &&(
            <h1 className="title-slider">{props.info}</h1>)
        }
        </>
     );
}
 
export default TitleSlider;