import React, { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import Login from './Login';
import Productos_Control from './Productos_Control';

const Control = () => {
    const { isLoggedIn } = useContext(AuthContext);
    return (
        <div className='control-container'>
            {
            isLoggedIn ?
                <Productos_Control />
            : 
                <Login />
            }
        </div>
    );
}
 
export default Control;