import React, { useState, useEffect } from "react";
import axios from "axios";

const useGetCategory = (url, postData) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(url, postData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                setData(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [url]);

    return { data, loading, error };
}

export default useGetCategory;
