import React,{useState,useEffect} from "react";
import styles from "./Nosotros.module.scss";
import Header from "../../shared/Header/Header";
import icon from "../../../img/nosotros-icon.png";
import TitleSlider from "../../elements/TitleSlider/TitleSlider";
import Slider from "../../elements/Slider/Slider";
import BannerHomeEnd from "../../elements/BannerHomeEnd/BannerHomeEnd";
import Footer from "../../shared/Footer/Footer";
import useGetProducts from "../../hooks/useGetProducts";

const Nosotros = () => {

    const [itemsP, setItemsP] = useState({});
    
    const urlProducts = 'https://www.obleasdulcesbsas.com.ar/api/getProduct.php';
    const postData = { "state": "A" };
  
    const { data: products, loading: loadingProducts, error: errorProducts } = useGetProducts(urlProducts,postData);
  
    useEffect(() => {
      if(products){setItemsP(products)};
    }, [products]);

    return (
        <>
            <div className={styles.container}>
                <h1 className={styles.title}>Obleas Dulces Buenos Aires</h1>
                <ol className={styles.list_text}>
                    <li>
                        Obleas Dulces Bs. As. S.R.L. es una empresa familiar que desde hace 30 años se dedica a la fabricación, venta y distribución de una amplia gama de productos elaborados a base de oblea, principalmente para su uso en el rubro del helado, confitería y pastelería artesanal.
                    </li>
                    <li>
                        En su planta productiva sita en la localidad de Aldo Bonzi, Partido de La Matanza, fabrica con la marca POLI diferentes tipos de productos, tales como cucuruchos, cucuruchotes y minicucuruchos, cubanitos y cubanones, capelinas, tulipas y canastitas, y otros tipos de obleas como pañuelitos y corazones.
                    </li>
                    <li>
                        Calidad de nuestros productos
                    </li>
                    <li>
                        <strong>* Elaboración artesanal:</strong> son elaborados de manera artesanal y sin utilizar conservantes, lo cual les permite tener ese gusto verdaderamente dulce de las obleas tradicionales.
                    </li>
                    <li>
                        <strong>* Envasado manual:</strong> los productos son envasados de manera manual pues la consistencia de las obleas es muy delicada. Ello hace que los productos lleguen al cliente siempre frescos y sin roturas.
                    </li>
                    <li>
                        Asimismo, la empresa brinda el servicio de distribución propia y a través de distribuidores en todo el ámbito del área metropolitana de Capital Federal y Gran Buenos Aires.
                    </li>
                </ol>
                <h1 className={styles.title}>Calidad</h1>
                <ol className={styles.list_text}>
                    <li>
                        Obleas Dulces Bs. As. S.R.L. es una empresa que desde siempre viene ofreciendo al mercado productos artesanales de alta calidad combinando las mejores materias primas y cuidando los procesos de elaboración, envasado y distribución para que lleguen al cliente en óptimas condiciones.
                    </li>
                    <li>
                        Nuestros productos son elaborados de forma totalmente artesanal y no industrial, por lo tanto no contienen ningún tipo de conservantes ni mejoradores químicos, logrando de esta manera una oblea natural, rica y saludable.
                    </li>
                    <li>
                        Ello nos ha granjeado el prestigio en el mercado local, como así también la confianza de clientes de primerísima línea, entre los que se cuentan cadenas de heladerías artesanales y confiterías de gran renombre.
                    </li>
                    <li>
                        Asimismo, al elaborar de manera artesanal, estamos en condiciones de diseñar y producir a pedido el tipo de producto según los requerimientos de cada cliente.
                    </li>
                    <li>
                        <img className={styles.icon} src={icon} alt="nosotros-icon" />
                    </li>
                </ol>
            </div>
            <TitleSlider info="Nuestros Productos"/>
            <Slider 
                style={itemsP.type}
                slidesPerView="4" 
                spaceBetween="20"
                typeOfItem={itemsP.type}
                items={itemsP}
                setItems={setItemsP}
            />
            <BannerHomeEnd />
        </>
        
    );
}
 
export default Nosotros;