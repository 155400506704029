import React from "react";
import styles  from "./BoxCategory.module.scss";

const BoxCategory = ({item}) => {
    return (
        <ul className={styles.box}>
            <li className={styles.name}>{item.name}</li>
            <li className={styles.img}>
                <img src={item.imgUrl} />
            </li>
        </ul>
    );
}
 
export default BoxCategory;


