import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";

const useGetProducts = (url, postData, updateP) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const memoizedPostData = useMemo(() => postData, [JSON.stringify(postData)]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(url, memoizedPostData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setData(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        // Debounce mechanism
        const handler = setTimeout(() => {
            fetchData();
        }, 300); // delay in ms

        return () => {
            clearTimeout(handler);
        };
    }, [url, memoizedPostData, updateP]);

    return { data, loading, error };
};

export default useGetProducts;
