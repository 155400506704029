import React, {useState, useEffect} from "react";
import "./Productos.scss";
import { useLocation } from "react-router-dom";
import Result from "../Result/Result";

const Productos = () => {
    const [itemsP, setItemsP] = useState({});
    
    //console.log(jorge);
    const location = useLocation();
    const { item, postData } = location.state || {};
    
    useEffect(() => {
        if (Array.isArray(item) && item !== undefined) {
            setItemsP(item);
        }
    }, [item]);
    
    return (
        <>
            <Result 
                setItems={setItemsP}
                itemsUrl={item}
                postData={postData}
            />
        </>        
    );
}
 
export default Productos;