import React,{useRef, useEffect  } from "react";
import  "./BannerHomeInit.scss";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import cucu from "../../../img/home-banner-cucu.png";
import icon from "../../../img/home-banner-icon.png";
import Btn from "../Btn/Btn";

// Registrar el plugin de ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

const BannerHomeInit = (props) => {
    const boxInit = useRef(null);
    const boxLogo = useRef(null);
    const boxInfo = useRef(null);
    const boxShape = useRef(null);

    useEffect(() => {
        /*gsap.fromTo(boxInit.current, 
          { opacity: 0, y: 20, x:20,scale:1 }, 
          { 
            opacity: 1, 
            y: 0,
            x:0,
            scale:0.9,
            duration:1
          }
        );*/
        gsap.fromTo(boxLogo.current, 
            { opacity: 0, y: 20 ,rotate:100}, 
            { 
              opacity: 1, 
              y: 0,
              rotate:0,
              duration:1
            }
        );
        gsap.fromTo(boxInfo.current, 
            { opacity: 0, y: 20}, 
            { 
              opacity: 1, 
              y: 0,
              delay:0.5,
              duration:2
            }
        );
    }, []);

    return (
        <div className="container-banner-home" ref={boxShape}>
            <div className="banner-icon">
                <div>
                    <img src={icon} alt="icon" ref={boxLogo}/>
                </div>
            </div>
            <div className="banner-txt" ref={boxInfo}>
                <ul>
                    <li className="banner-title">Obleas dulces para Pastelería y Heladería</li>
                    <li className="banner-info">Oblea 100% natural, 100% artesanal</li>
                    <li className="container-btn">
                        <Btn cta text="Ver Productos"/>
                    </li>
                </ul>
            </div>
            <div className="banner-img">
                <img src={cucu} alt="cucu" ref={boxInit}/>
            </div>
        </div>
    );
}
 
export default BannerHomeInit;