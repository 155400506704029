import React, { useState, useEffect } from "react";
import "./template.scss";

import {useNavigate } from "react-router-dom";
import BoxResult from "../../shared/BoxResult/BoxResult";
import useGetProducts from "../../hooks/useGetProducts";
import useGetCategory from "../../hooks/useGetCategory";
import useGetSubCategories from "../../hooks/useGetSubCategories";
import Modal from "../../elements/Modal/Modal";
import Loading from "../../shared/Loading/Loading";

const Result_Control = ({typeOfItem,items,itemsUrl,postData}) => {

    const navigate = useNavigate ();
    
    const [productUrl , setProductUrl] = useState({});
    const [nameCateg, setNameCateg] = useState("");
    const[postDataSubC,setpostDataSubC] = useState({});
    const [updateP, setUpdateP] = useState(false);
    const [modalIsOpen , setModalIsOpen] = useState(false);
    const [itemModal,setItemModal] = useState({});
    const [actionModal,setActionModal] = useState("create");
    
    const url = "https://www.obleasdulcesbsas.com.ar/api/getProduct.php";
    const {data:ProductosUrl, loading:loadingProductosUrl, error: errorProductosUrl} = useGetProducts(url,postData,updateP);

    const urlSubCategories = 'https://www.obleasdulcesbsas.com.ar/api/getSubCategory.php';
    const {data:subCategories, loading:loadingSubCategories, error: errorSubCategories} = useGetSubCategories(urlSubCategories,postDataSubC);

    const urlCategories = 'https://www.obleasdulcesbsas.com.ar/api/getCategory.php';
    const { data: categories, loading: loadingCategories, error: errorCategories } = useGetCategory(urlCategories, { "state": "A" });

    useEffect(() =>{
        if(itemsUrl){

            itemsUrl.id_category?setpostDataSubC({"id_category":itemsUrl.id_category}):setpostDataSubC({"id_category":itemsUrl.id});
            itemsUrl.name_category ? setNameCateg(itemsUrl.name_category) : setNameCateg(itemsUrl.name);
            
        }else{
            setNameCateg("Todos los productos")
        }        
        if(ProductosUrl){setProductUrl(ProductosUrl)};
    }, [itemsUrl]);

    const handleClick = (route, item, type) => {
        let postData;
        
        if (type === "category") {
            postData = { "id_category": item.id , mood:"admin"};
        } else if(type === "subcategory") {
            postData = { "id_subcategory": item.id , mood:"admin"};
        }else{
            postData = { mood:"admin" };
        }
        navigate(route, { state: { item, postData } });
    }

    const handleCreate = () => {
        setModalIsOpen(true);
        setActionModal("create");
    }

    if (loadingProductosUrl || loadingSubCategories || loadingCategories) return <Loading />;

    return ( 
        <div className="container-result">
            {/*<div className="box-breadcurn">
                <p><Link to="/">BreadCurn</Link></p><span>/</span><p><Link to="/">BreadCurn</Link></p><span>/</span><p><Link to="/">BreadCurn</Link></p>
            </div>*/}
            <div className="box-filter">
                <div className="btn-create" onClick={() => handleCreate()}>+</div>
                <h1>
                    {nameCateg}
                </h1>
                <ul>
                    {
                        Array.isArray(categories?.data) ?
                        categories.data.map((i, index) => (
                            <li key={index}>
                                <a onClick={() => handleClick("/productos-control", i, "category")}>{i.name}</a>
                                {
                                     Array.isArray(subCategories?.data) ?
                                    subCategories.data.map((sub, index) => (
                                        i.id == sub.id_category ?
                                            <span
                                            className="subcategories_control"
                                            key={index} 
                                            onClick={() => handleClick("/productos-control", sub, "subcategory")}>
                                                {sub.name}
                                            </span>
                                        :
                                        <></>
                                    ))
                                    :
                                    <></>
                                }
                            </li>
                        ))
                        :
                        <>no existen categorías</>
                            
                    }
                </ul>
            </div>
            <div className="box-products">
                <h1>
                    {nameCateg}
                    <span>
                        (
                        {
                            Array.isArray(ProductosUrl?.data) ?
                            <>{ProductosUrl.data.length}</>
                            :
                            Array.isArray(items?.data) ?
                            <>{items.data.length}</>
                            :
                            <p>No items found</p>
                        }
                        )
                    </span>
                </h1>
                <div className="products">
                    {
                        Array.isArray(ProductosUrl?.data) ?
                            ProductosUrl.data.map((i, index) => (
                                <BoxResult
                                    key={index}
                                    className={typeOfItem}
                                    item={i}
                                    mood="admin"
                                    setUpdateP={setUpdateP}
                                    setModalIsOpen={setModalIsOpen}
                                    setItemModal={setItemModal}
                                    setActionModal={setActionModal}
                                />
                            )) 
                        :
                        Array.isArray(items?.data) ?
                            items.data.map((i, index) => (
                                <BoxResult
                                    key={index}
                                    className={typeOfItem}
                                    item={i}
                                    mood="admin"
                                    setUpdateP={setUpdateP} 
                                    setModalIsOpen={setModalIsOpen}
                                    setItemModal={setItemModal}
                                    setActionModal={setActionModal}
                                />
                            )) :
                        <p>No items found</p>
                    }
                </div>
            </div>
            <Modal 
                modalIsOpen={modalIsOpen} 
                setModalIsOpen={setModalIsOpen} 
                action={actionModal}
                itemModal={itemModal}
                setUpdateP={setUpdateP}
                categories={Array.isArray(categories?.data) ? categories.data : []}
                subcategories={Array.isArray(subCategories?.data) ? subCategories.data : []}
            />
        </div>
    );
}
 
export default Result_Control;