import React from "react";
import Style from "./Loading.module.scss";

const Loading = () => {
    return (
        <div className={Style.container}>
            <div className={Style.spinner}></div>
        </div>
    );
}
 
export default Loading;