import React,{useState} from "react";
import "./Disclaimer.scss";
import DataFiscal from "../../../img/data-fiscal.jpg";
import { Link } from "react-router-dom";

const Disclaimer = (props) => {
    
    const showIt = props.text ? true : false;

    return (
        <>
        {
            showIt &&
            <section className="container-disclaimer">
                <p>{props.text}</p>
                <div className="data-fiscal">
                    <Link to="https://servicioscf.afip.gob.ar/publico/denuncias/denunciaCD.aspx" target="blank">
                        <img src={DataFiscal} alt="data-fiscal"/>
                    </Link>
                </div>
            </section>
        }
        </>
        
    );
}
 
export default Disclaimer;