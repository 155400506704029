// Layout.js
import React, {useState,useEffect, useContext} from "react";
import styles from "./Layout.module.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import useGetCategory from "../../hooks/useGetCategory";
import useGetProducts from "../../hooks/useGetProducts";
import useGetSubCategories from "../../hooks/useGetSubCategories";
import { AuthContext } from "../../../context/AuthContext";
import Disclaimer from "../Disclaimer/Disclaimer";
import Loading from "../Loading/Loading";

const Layout = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);
  console.log(isLoggedIn);

  const [itemsC, setItemsC] = useState({});
  const [itemsP, setItemsP] = useState({});
  const [itemsSubC, setItemsSubC] = useState({});
  
  const urlCategories = 'https://www.obleasdulcesbsas.com.ar/api/getCategory.php';
  const urlProducts = 'https://www.obleasdulcesbsas.com.ar/api/getProduct.php';
  const urlSubCategories = 'https://www.obleasdulcesbsas.com.ar/api/getSubCategory.php';
  const postData = { "state": "A" };

  const { data: categories, loading: loadingCategories, error: errorCategories } = useGetCategory(urlCategories, postData);
  const { data: products, loading: loadingProducts, error: errorProducts } = useGetProducts(urlProducts,postData);
  const { data: subCategories, loading: loadingsubCategories, error: errorsubCategories } = useGetSubCategories(urlSubCategories,postData);

  useEffect(() => {
    if(categories){setItemsC(categories)};
    if(products){setItemsP(products)};
    if(subCategories){setItemsSubC(subCategories)};
  }, [categories,products]);

  if (loadingCategories || loadingProducts || loadingsubCategories) return <Loading /> ;
  if (errorCategories || errorProducts || errorsubCategories) return <p>Error: {errorCategories?.message || errorProducts?.message}</p>;

  return (
    <>
      {!isLoggedIn ?
        <>
          <Header 
            itemsC={itemsC}
            setItemsC={setItemsC}
            itemsSubC={itemsSubC}
            setItemsSubC={setItemsSubC}
          />
          <main className={styles.container}>{children}</main>
          <Footer />
          <Disclaimer text="© 2024 OBLEAS DULCES. Todos los derechos reservados." /> 
        </>
        :
        <main className={styles.container}>{children}</main>
      }
    </>
  );
};

export default Layout;
