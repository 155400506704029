import React, {useState, useEffect} from "react";
import "./template.scss";
import { useLocation } from "react-router-dom";
import Result_Control from "./Result_Control";


const Productos_Control = () => {
    const [itemsP, setItemsP] = useState({});
    
    const location = useLocation();
    const { item, postData } = location.state || {};
    
    useEffect(() => {
        if (Array.isArray(item) && item !== undefined) {
            setItemsP(item);
        }
    }, [item]);
    
    return (
        <>
            <p className="mood">Modo Administrador</p> 
            <Result_Control 
                setItems={setItemsP}
                itemsUrl={item}
                postData={postData}
            />
        </>        
    );
}
 
export default Productos_Control;